<template>
  <div class="address-list">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>主营业务</span>
      <span></span>
    </header>
    <main class="page-body">
      <van-tabs v-model="active">
        <van-tab
          v-for="item in serviceTypeList"
          :key="item.PK_ID"
          :title="item.sName"
        >
          <div class="list">
            <div
              class="card box-half"
              v-for="it in getProjectListByType(item.PK_ID)"
              :key="it.PK_ID"
              @click="
                $router.push({
                  name: 'ServiceDetail',
                  query: { id: item.PK_ID },
                })
              "
            >
              <img
                :src="
                  it.sPicUrl != null && it.sPicUrl.startsWith('http')
                    ? it.sPicUrl
                    : baseUrl + it.sPicUrl
                "
              />
              <div class="title">{{ it.sProdName }}</div>
              <div class="desc">
                {{ it.sDesc }}
              </div>
              <div class="x-center">
                <van-button type="info" size="mini">查看详情</van-button>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </main>
  </div>
</template>
<script>
import { getProjectTypeList, getServiceList } from '@/api/website.js';
import { BASE_URL } from '@/api/index.js';
// import { formatDate } from '@/utils/index.js';
// function getValidTime(value) {
//   if (value == null || value == '') return '';
//   var myDate = new Date(value.match(/\d+/)[0] * 1);
//   return formatDate(myDate);
// }
export default {
  data() {
    return {
      active: 0,
      serviceTypeList: [],
      activeServiceList: [],
      baseUrl: BASE_URL,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      getProjectTypeList().then(res => {
        if (res.Result == true) {
          this.serviceTypeList = res.DataDict['data'];
        }
      });

      getServiceList(100).then(res => {
        if (res.Result == true) {
          this.activeServiceList = res.DataDict['data'];
          console.log(res);
        }
      });
    },
    getProjectListByType(typeId) {
      return this.activeServiceList.filter(el => el.iType == typeId);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(20px);
}
.card {
  margin: rem(15px);
  border-radius: rem(10px);
  background-color: #fff;
  padding: rem(20px);
  img {
    width: 95%;
    height: rem(159px);
    text-align: center;
  }
}
.title {
  margin-top: rem(20px);
  margin-bottom: rem(24px);
  color: #666;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}
.desc {
  font-size: 12px;
  color: #b8b8b8;
  margin-bottom: rem(10px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.box-half {
  width: 40%;
  float: left;
  position: relative;
}
.van-button {
  padding-left: rem(40px);
  padding-right: rem(40px);
}
</style>
